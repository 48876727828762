<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="../../assets/images/dengbaozhengming/a02.jpg">
                </div>
				<div class="con_top_right">
					<h2>等保咨询服务</h2>
					<p class="new_tit">新品大促，万人疯抢中，尽快选购吧！</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">在线咨询</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                           <p>等保咨询服务，等级保护备案证明是由客户在办理等保测评的时候由当地公安核发的一个备案证明</p>
                        </div>
					</div>
					<div class="box_ti">
						<div class="ti_left">
                            <p>分</p><p>类</p>
                        </div>
						<div class="box_fenlei">
							<span class="">等保备案</span>
							<span class="">专家评审</span>
						</div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>


		<div class="conten_body">  
			<div class="conten_left">
				<ul class="nav_list">
					<li><a class="a_active" href="">产品介绍</a></li>
					<li><a href="">服务流程</a></li>
					<li><a href="">服务优势</a></li>
					<li><a href="">常见问题</a></li>
				</ul>
				<div class="con_list">
					<h3 style="margin-top:15px;">
						<img class="icons" src="@/assets/images/b04.png">
					    什么是等级保护备案证明？
				     </h3>
				   <img class="deng_icon" src="@/assets/images/b05.png">
				   <p class="deng_item">
				   	<span></span>
                    等级保护备案证明是由客户在办理等保测评的时候由当地公安核发的一个备案证明。
				   </p>
				    <h3 style="margin-top:15px;">
						<img class="icons" src="@/assets/images/b04.png">
					    什么情况下需要办理备案证明？
				    </h3>
				    <div class="kai_items">
				    	<p>
				    	 <span class="kai_item_tit"><i>1</i>教育app备案</span><br>
				    	教育部门要求企业在在做教育白名单认证的时候需要企业提供网络安全等级保护备案证明电子商务平台。</p>
				    </div>
				    <div class="kai_items">
				    	<p>
				    		<span class="kai_item_tit"><i>2</i>电子商务平台</span><br>
				    	电子商务平台在做电商许可证时需要用到一份等级保护备案证明的材料，用户办理电商许可证。</p>
				    </div>
				    <div class="kai_items">
				    	
				    	<p>
				    		 <span class="kai_item_tit"><i>3</i>物流平台</span><br>
				    	各类物流企业在办理快递运营许可或者年检时需要提供等级保护备案证明。</p>
				    </div>
				      <!-- <p>
                    	<a class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p> -->

				</div>
              <!-- 为什么要开展等保工作？ -->
			<!-- 	<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     为什么要开展等保工作？
				    </h3>
				    <div class="kai_items">
				    	<img class="kai_icons" src="@/assets/images/b06.png">
				    	<p>等级保护是我国关于信息安全的基本政策，国家法律法规、相关政策制度要求单位开展等级保护工作。如《网络安全等级保护管理办法》和《中华人民共和国网络安全法》；</p>
				    </div>
				    <div class="kai_items">
				    	<img class="kai_icons" src="@/assets/images/b07.png">
				    	<p>越来越多的行业，主管/监管单位明确要求企业开展等级保护工作，目前已经下发行业要求文件的有：金融、电力、广电、医疗、教育、物流等行业，等保为准入门槛之一；</p>
				    </div>
				    <div class="kai_items">
				    	<img class="kai_icons" src="@/assets/images/b08.png">
				    	<p>互联网的发展同步带来网络安全事故频发，通过等级保护工作发现企业本身信息系统存在的安全隐患和不足，进行安全整改之后，提高信息系统的信息安全防护能力，降低系统被各种攻击的风险，避免信息系统核心数据丢失，及因此而带来直接或者间接的经济损失，维持企业良好的形象。</p>
				    </div>
				    <p class="kai_desc">因此相关单位与企业有责任与义务开展等级保护工作，落实个人及单位的网络安全保护义务，合理规避风险。</p>
				    <p class="kai_desc1">开展网络安全等级保护测评工作刻不容缓！</p>
				    <div class="kai_ico">
				    	<img class="kai_icons1" src="@/assets/images/b09.png">
				    	法律依据
				     </div>
				    <p class="deng_item">
				     	<span></span>
                      《中华人民共和国网络安全法》第二十一条规定国家实行网络安全等级保护制度，网络运营者不履行本法第二十一条、第二十五条规定的网络安全保护义务的，由有关主管部门责令改正，给予警告；拒不改正或者导致危害网络安全等后果的，处一万元以上十万元以下罚款，对直接负责的主管人员处五千元以上五万元以下罚款。
                    </p>
                    <p class="deng_item">
				     	<span></span>
                         2018年11月1日开始执行《网络安全法》   
                    </p>
                     <div class="kai_ico">
				    	<img class="kai_icons1" src="@/assets/images/b10.png">
				    	技术指标
				    </div>
				    <p class="deng_item">
				     	<span></span>
                        2008年发布的《GB/T22239-2008 信息安全技术 信息系统安全等级保护基本要求 》简称为等保1.0。        
                    </p>
                    <p class="deng_item">
				     	<span></span>
                         2017年8月，公安部评估中心根据网信办和安标委的意见将等级保护在编的5个基本要求分册标准进行了合并形成《网络安全等级保护基本要求》一个标准，简称等保2.0。   
                    </p>
                    <p>
                    	<a class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p>
				</div> -->
				<!-- 顶级保护实施流程 -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     等级保护实施流程
				   </h3>
				   <div  class="liu_icon">
				   	    <img src="@/assets/images/b12.png">
				   </div>
				  <!--  <h3>
						<img class="icons" src="@/assets/images/b04.png">
					     等保测评周期
				   </h3>
				   <div class="zhou_box">
				   	   <img class="zh_icon" src="@/assets/images/b13.png">
			   	    	<span>1.定级备案约7个工作日 </span>
			   	    	<span>2.项目备案约7个工作日 </span>
			   	    	<span>3.差距测评约10个工作日</span>
			   	    	<span>4.等保复评约7个工作日</span>
			   	    	<span>5.出具报告约3个工作日 </span>
				   </div>
				    <p class="zh_desc">（具体时间还要看需要测评的系统数量）</p>
				    <p class="zh_red">整改加固需要客户配合，时长要看客户整改进度 <br/>等保测评和复测工作均需要授权测评机构上门调研，并进行实地测试</p>
				    <p style="margin-top:0" class="kai_desc">建议收到上级、监管单位通知需要做等保测评时尽快开始，等保测评需要一定周期，尽早做、更安心。</p>
				    <p style="color:#2A2A2B" class="kai_desc1">等保二级2年测评一次；等保三级1年测评一次。</p>
				     <p>
                    	<a style="margin-top:10px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p> -->
				</div>
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     为什么选择万方？万方有什么优势？
				   </h3>
				   <div class="why">
				   	   <img class="why_icon" src="@/assets/images/b14.png">
				   	   <ul>
				   	    	<li><span></span>万方安全成立至今十多年，一直专注于网络安全事业；</li>
				   	    	<li><span></span>万方是有资质的企业，风险评估、安全工程等资质；  </li>
				   	    	<li><span></span>服务全国，专业安全服务备受好评； </li>
				   	    	<li><span></span>量身定制专属方案，节省费用； </li>
				   	    	<li><span></span> 实施效率高，过程透明，拿证快； </li>
				   	    	<li><span></span>各个行业均有多个成功案例； </li>
				   	    	<li><span></span> 阿里云等保咨询金牌合作伙伴；</li>
				   	    	<li><span></span>保密机制； 一站式服务。 </li>
				   	   </ul>
				   </div>
				</div>
				<!-- 等保测评的价格 -->
			<!-- 	<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     等保测评的价格
				   </h3>
				   <div class="pri">
				   	   <img class="pri_img" src="@/assets/images/b15.jpg">
				   	   <p style="color:#0079EE;" class="zh_desc">（实际价格还要看测评地区及测评的系统数量、大小）</p>
				   </div>
				</div> -->
				<!-- 客户能得到哪些收益 -->
				<div class="con_list">
					<!-- <h3>
						<img class="icons" src="@/assets/images/b04.png">
					     客户能得到哪些收益
				   </h3>
				    <div class="shouyi">
				   	   <ul>
				   	    	<li><span>1</span>满足合规性需求；</li>
				   	    	<li><span>2</span>了解业务安全边界，提升了业务系统安全和网络防护能力； </li>
				   	    	<li><span>3</span>保护企业核心信息及客户信息； </li>
				   	    	<li><span>4</span> 建设完善的网络安全管理制度，长制久安；</li>
				   	    	<li><span>5</span> 相比未过等保的，更有竞争力。 </li>
				   	   </ul>
				   	    <img class="why_icon" src="@/assets/images/b16.png">
				   </div> -->
				   <!-- <p>
                    	<a style="margin-top:10px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p> -->
				</div>
				<!-- 行业案例 -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     行业案例
				   </h3>
				   <ul class="case">
				   	 <li>
				   		<img class="case_img" src="@/assets/images/dengbaozhengming/a02.png">
				   		<p class="case_tiss">佛山地区--3级备案证明</p>
				   	 </li>
				   	  <li style="margin-top:20px;">
				   		<img class="case_img" src="@/assets/images/dengbaozhengming/a03.png">
				   		<p class="case_tiss">深圳地区--2级备案证明</p>
				   	 </li>
				   	  <li style="margin-top:30px;">
				   		<img class="case_img" src="@/assets/images/dengbaozhengming/a04.png">
				   		<p class="case_tiss">深圳地区--2级备案证明</p>
				   	 </li>
				   	  <li style="margin-top:60px;">
				   		<img class="case_img" src="@/assets/images/dengbaozhengming/a05.png">
				   		<p class="case_tiss">深圳地区--3级备案证明</p>
				   	 </li>
				   </ul>
				  
				   <!-- <p style="" class="kai_desc1">等保测评刻不容缓，找万方！</p> -->
				     <!-- <p>
                    	<a style="margin-top:10px;margin-bottom:30px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p> -->
				</div>
			</div>
			<!-- right -->
			<Hotgoods/>

		</div>
	</div>
</template>

<script type="text/javascript">

import Hotgoods from "@/components/hotgoods.vue"

export default{

components:{
  Hotgoods,
},
data(){
	return{
		transmitData:{
			title:"等保咨询服务",
			content:"等保咨询服务，等级保护备案证明是由客户在办理等保测评的时候由当地公安核发的一个备案证明",
			price:'5000',
			url:require('../../assets/images/dengbaozhengming/a02.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;


.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background:url('../../assets/images/b02.png');
	height:30px;
	line-height: 30px;
	font-size:12px;
	color:#fff;
	padding-left:20px;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 35px;
    padding-right: 2px;
    font-weight: bold;
}
.pri_box  .price{
	font-size: 26px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
       p{
		    font-size: 14px;
            line-height: 16px;
	   }
    }
}
.box_ti{
   margin-top:30px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}
.box_fenlei{
	display: inline-block;
}
.box_fenlei span{
    width: 112px;
	height: 32px;
	display: inline-block;
	border: 1px solid #CFCDCD;
	font-size: 13px;
	font-weight: 300;
	color: #717171;
	text-align: center;
	line-height: 32px;
	margin-right:19px;
	cursor: pointer;
}



//**/
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	padding:0 35px;
	overflow:hidden;
}
.con_list h3{
	font-size: 22px;
	font-weight: bold;
	color: #2B2B2B;
	line-height: 42px;
	margin-top:40px;
}
.icons{
	width:11px;
	height:28px;
	position:relative;
	top:-2px;
}
.deng_icon{
	width: 280px;
    height: 232px;
    float:right;
    margin-left:10px;
}
.deng_item {
	position:relative;
	font-size: 16px;
	font-weight: 300;
	color: #2B2B2B;
	line-height: 23px;
	margin-left:20px;
	margin-top:15px;
}
.deng_item span{
	width: 6px;
	height:6px;
	border: 2px solid #007CC6;
	border-radius: 50%;
	position:absolute;
	top:5px;
	left:-18px;
}
.kai_items{
	margin-top:12px;
    overflow:hidden;
	font-size: 16px;
	font-weight: 300;
	color: #2B2B2B;
	line-height: 23px;
	display: table;
}
.kai_icons{
	width: 138px;
    height: 138px;
    float:left;
    margin:0 15px;
}
.kai_items p{
   display: table-cell;
   vertical-align: middle;
   width: auto;
   height:auto;
   padding-left:25px;
}
.kai_desc{
	font-size:16px;
	font-weight: 400;
	color: #007CC6;
	line-height: 42px;
	text-align: center;
	margin-top:30px;
}
.kai_desc1{
	font-size:19px;
	font-weight: bold;
	color: #007CC6;
	line-height: 42px;
	text-align:center;
}
.kai_ico{
    font-size:19px;
	font-weight: 600;
	color: #000000;
	margin-top:15px;
}
.kai_ico img{
  width:45px;
  position:relative;
  top:-3px;
  margin-left:20px;

}
.kai_phone_box{
	display: inline-block;
	margin:0 auto;
	position:relative;
	margin-top:35px;
	left:50%;
	width:250px;
	margin-left:-125px;
}
.liu_icon{
	margin-top:20px;
    text-align: center;
}
.liu_icon img{
	padding:0 25px;
}
.zhou_box{
	position:relative;
	text-align:center;
	width:175px;
	height:175px;
	margin:0 auto;
	margin-top:20px;
}
.zh_icon{
	width:175px;
	height:175px;
}
.zh_desc{
	font-size:14px;
	font-weight: 600;
	color: #000000;
	text-align:center;
	line-height: 42px;
}
.zhou_box>span{
	position:absolute;
	width:200px;
	font-size:16px;
	font-weight: 300;
	color: #2A2A2B;
	line-height: 42px;
}
.zhou_box>span:nth-of-type(1){
	top:0;
	right:170px;
}
.zhou_box>span:nth-of-type(2){
	top:65px;
	right:200px;
}
.zhou_box>span:nth-of-type(3){
	bottom:0;
	right:170px;
}
.zhou_box>span:nth-of-type(4){
	top:0;
	left:170px;
}
.zhou_box>span:nth-of-type(5){
	top:65px;
	left:200px;
}
.zh_red{
	font-size:16px;
	font-weight: bold;
	color: #C52000;
	line-height: 19px;
   text-align:center;
   margin-top:10px;
}
.why{
	margin-top:10px;
	overflow:hidden;
}
.why_icon{
	width: 265px;
    float:left;
    margin-left:20px;
}
.why ul{
	float:left;
	margin-left:50px;
	margin-top:15px;
}
.why ul li{
	font-size:15px;
	font-weight:500;
	color: #2B2B2B;
	line-height: 29px;
}
.why ul li span{
	width: 5px;
	height: 5px;
	margin-right:3px;
	display:inline-block;
	border: 2px solid #007CC6;
	border-radius: 50%;
}
.shouyi{
	margin-top:10px;
	overflow:hidden;
}
.shouyi img{
	float:left;
	width:258px;
	margin-left:65px;
}
.shouyi ul{
	float:left;
	margin-left:20px;
}
.shouyi ul li{
	font-size:15px;
	color: #2B2B2B;
	line-height: 42px;
}
.shouyi ul span{
	width: 20px;
	display: inline-block;
	height: 20px;
	border: 2px solid #007CC6;
	border-radius: 50%;
	font-size:15px;
	color: #1F79FF;
	text-align: center;
	line-height: 20px;
	margin-right:5px;
}
.case{
	overflow: hidden;
}
.case li{
	width:50%;
	float:left;
	padding:0 20px;
	box-sizing:border-box;
}
.case li .case_img{
	width:100%;
}
.case_items{
	flex:1;
	padding:10px;
}
.case_items h6{
	font-size:20px;
	font-weight: bold;
	color: #268CF5;
	line-height: 42px;
	margin-top:15px;
}
.case_items_list{
	font-size:16px;
	color: #272626;
	line-height: 26px;
	// display: flex;
}
.more_case{
	width: 159px;
	height: 41px;
	font-size:12px;
	line-height: 40px;
	display: block;
	margin:10px auto;
	text-align:center;
	color:#157AE4;
	border: 1px solid #007CC6;
	cursor:pointer;
}
.kai_item_tit{
	font-size:16px;
	color:#007CC6;
	line-height: 20px;
	position:relative;
	left:-22px;
}
.kai_item_tit i{
	width:18px;
	height:18px;
	text-align:center;
	font-size:14px;
	line-height: 18px;
	display: inline-block;
	border-radius:50%;
	border:1px solid #007CC6;
	margin-right:3px;
}
.case_tiss{
	text-align: center;
	font-size:15px;
	line-height:30px;
}





}


</style>







